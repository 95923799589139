import { DescriptionItem, GridNull, Stack, Text } from "@gigsmart/atorasu";
import { LocationUpdates } from "@gigsmart/feature-flags";
import { numeric } from "@gigsmart/isomorphic-shared/iso";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import React from "react";
import { showLocation } from "react-native-map-link";

import type { ParkingInfoHeaderRow_orgLocation$key } from "./__generated__/ParkingInfoHeaderRow_orgLocation.graphql";

type Props = {
  workerDistance: number;
};

export const ParkingInfoHeaderRow = ({
  parkingLocations,
  workerDistance
}: FragmentContainerInnerComponentProps<
  ParkingInfoHeaderRow_orgLocation$key,
  Props
>) => {
  const parkingLocation = parkingLocations?.edges?.[0]?.node;

  if (!parkingLocation || LocationUpdates.isDisabled()) return <GridNull />;

  return (
    <Stack size="none">
      <Text>
        <Text weight="bold">Parking Location • </Text>
        <Text>
          {`${numeric.humanize(workerDistance, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 1
          })} mi`}{" "}
          away
        </Text>
      </Text>
      <DescriptionItem
        title={parkingLocation.place.streetAddress ?? ""}
        onTitlePress={() =>
          showLocation({
            latitude: parkingLocation.location?.latitude ?? "",
            longitude: parkingLocation.location?.longitude ?? "",
            googlePlaceId: parkingLocation.place?.id ?? "",
            alwaysIncludeGoogle: true,
            dialogTitle: "Get Directions",
            dialogMessage: "Select a preferred navigation app"
          })
        }
      />
    </Stack>
  );
};

export default createRelayFragmentContainer<
  ParkingInfoHeaderRow_orgLocation$key,
  Props
>(
  graphql`
    fragment ParkingInfoHeaderRow_orgLocation on OrganizationLocation {
      parkingLocations(first: 1) {
        edges {
          node {
            place {
              id
              streetAddress
            }
            location {
              latitude
              longitude
            }
          }
        }
      }
    }
  `,
  ParkingInfoHeaderRow
);
