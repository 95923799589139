import {
  InlineMapView,
  type LatLng,
  MapCircle,
  useMapRef
} from "@gigsmart/atorasu/map";
import React, { useCallback, useEffect } from "react";

interface Props {
  center: {
    latitude: number | null;
    longitude: number | null;
  };
}

export const ShiftLocationMap = ({ center }: Props) => {
  const mapRef = useMapRef();

  const latLng =
    typeof center.latitude === "number" && typeof center.longitude === "number"
      ? (center as LatLng)
      : null;

  const handleCenter = useCallback(() => {
    if (latLng) {
      mapRef?.current?.setCamera({ center: latLng, zoom: 14.5 });
    }
  }, [latLng]);

  useEffect(() => {
    handleCenter();
  }, [handleCenter]);

  return (
    <InlineMapView mapRef={mapRef} lite onMapReady={handleCenter}>
      {!!latLng && <MapCircle coordinate={latLng} />}
    </InlineMapView>
  );
};
