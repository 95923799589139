import { Row } from "@gigsmart/atorasu";
import {
  graphql,
  useRelayFragment,
  useRelayOrchestrator,
  useRelaySubscription
} from "@gigsmart/relay";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Image, type LayoutChangeEvent } from "react-native";
import type { LocationStaticMapQuery } from "./__generated__/LocationStaticMapQuery.graphql";
import type { LocationStaticMapSubscription } from "./__generated__/LocationStaticMapSubscription.graphql";
import type { LocationStaticMap_node$key } from "./__generated__/LocationStaticMap_node.graphql";

interface Props {
  locationId: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  padding?: number;
  width?: number;
  height?: number;
  step?: number;
}

export default function LocationStaticMap({
  locationId,
  width: requestedWidth,
  height: requestedHeight = requestedWidth && requestedWidth * (3 / 4),
  minWidth = 0,
  minHeight = 0,
  maxWidth = Infinity,
  maxHeight = Infinity,
  padding = 0,
  step = 2
}: Props) {
  const { fetchQuery } = useRelayOrchestrator();
  const [fragmentRef, setFragmentRef] =
    useState<LocationStaticMap_node$key | null>(null);
  const [finalWidth, setFinalWidth] = useState(requestedWidth);
  const [finalHeight, setFinalHeight] = useState(requestedHeight);

  const { adjustedHeight, adjustedWidth } = useMemo(() => {
    if (!finalHeight || !finalWidth) return {};
    const factor = 10 ** step;
    const aspectRatio = finalHeight / finalWidth;
    const adjustedWidth = Math.ceil(finalWidth / factor) * factor;
    const adjustedHeight = Math.round(adjustedWidth * aspectRatio);
    return { adjustedHeight, adjustedWidth };
  }, [finalHeight, finalWidth, step]);

  const data = useRelayFragment(
    graphql`
    fragment LocationStaticMap_node on OrganizationLocation
      @argumentDefinitions(
        height: { type: "Int!" }
        width: { type: "Int!"}
      ) {
        staticMapUrl(height: $height, width: $width)
      }
    `,
    fragmentRef
  );

  useRelaySubscription<LocationStaticMapSubscription>(
    graphql`
    subscription LocationStaticMapSubscription($id: ID!, $height: Int!, $width: Int!) {
      nodeUpdated(id: $id) {
        node {
          ...LocationStaticMap_node @arguments(height: $height, width: $width)
        }
      }
    }
    `,
    { id: locationId, height: adjustedHeight ?? 0, width: adjustedWidth ?? 0 },
    {
      subscribe: !!adjustedHeight && !!adjustedWidth,
      onNext: (data) => setFragmentRef(data?.nodeUpdated?.node ?? null)
    }
  );

  const uri = data?.staticMapUrl;

  // Set Width Based on Layout
  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (requestedWidth) return setFinalWidth(requestedWidth);
      const { width: layoutWidth } = event.nativeEvent.layout;
      const absoluteMin = Math.min(layoutWidth, maxWidth);
      const absoluteMax = Math.max(
        absoluteMin,
        Math.min(minWidth, layoutWidth)
      );
      setFinalWidth(Math.round(absoluteMax - padding * 2));
    },
    [requestedWidth, maxWidth, minWidth, padding]
  );

  // Set Height
  useEffect(() => {
    if (requestedHeight) return setFinalHeight(requestedHeight);
    if (!finalWidth) return;
    setFinalHeight(
      Math.round(
        Math.max(Math.min(finalWidth * (3 / 4), maxHeight), minHeight) -
          padding * 2
      )
    );
  }, [finalWidth, requestedHeight, maxHeight, minHeight, padding]);

  // Fetch Static Map
  useEffect(() => {
    if (!adjustedHeight || !adjustedWidth) return;
    const fetchData = async () => {
      const result = await fetchQuery<LocationStaticMapQuery>(
        graphql`
          query LocationStaticMapQuery($id: ID!, $height: Int!, $width: Int!) {
            node(id: $id) {
              ...LocationStaticMap_node @arguments(height: $height, width: $width)
            }
          }
        `,
        {
          id: locationId,
          height: adjustedHeight,
          width: adjustedWidth
        }
      );
      setFragmentRef(result.node ?? null);
    };
    fetchData().catch(console.error);
  }, [adjustedHeight, adjustedWidth, locationId, step]);

  return (
    <Row fill onLayout={handleLayout} justifyContent="center">
      {uri && (
        <Image
          onError={console.error}
          source={{ uri }}
          style={{ height: finalHeight, width: finalWidth, padding }}
          height={finalHeight}
          width={finalWidth}
          resizeMode="cover"
        />
      )}
    </Row>
  );
}
