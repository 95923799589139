import {
  Button,
  ContentArea,
  FancyHeader,
  GridNull,
  IconCircle,
  IsConstrainedProvider,
  Link,
  Stack,
  Surface,
  Text,
  Well,
  showEmbeddedBrowser
} from "@gigsmart/atorasu";
import {
  GenericBankingAd,
  GenericFoundAd,
  SpecificFoundAd
} from "@gigsmart/feature-flags";
import { useCurrentUser } from "@gigsmart/isomorphic-shared/current-user";
import { useUserValue } from "@gigsmart/isomorphic-shared/user-value";
import qs from "query-string";
import React from "react";

import BenefitsSVG from "../../benefits/BenefitsSVG";
import { useUserHasPendingConsents } from "../../user-consent/UserDocumentConsentCheck";
import FoundBanner from "./FoundBanner";

interface Props {
  screen: "HOME" | "WALLET" | "PAYMENT_STEP";
  inset?: boolean;
}

export default function BankingAdCard({ inset = false, screen }: Props) {
  const userHasPendingConsents = useUserHasPendingConsents();
  const { title, testID, userValue } = computeCardOptions();
  const [shown, setShown, isLoading] = useUserValue<boolean>(
    `${userValue}_${screen}`
  );
  const user = useCurrentUser();

  const content = renderContent(screen, user?.uuid);
  const isDisabled =
    !!shown || !content || userHasPendingConsents || !!isLoading;

  return isDisabled ? (
    <GridNull fallback="standard" />
  ) : (
    <ContentArea size="none" variant={inset ? "standard" : "none"}>
      <IsConstrainedProvider value={inset}>
        <Surface testID={testID} variant="mixed">
          <FancyHeader
            icon="piggy-bank"
            title={title}
            onDismiss={async () => await setShown(true)}
          />
          <ContentArea>{content}</ContentArea>
        </Surface>
      </IsConstrainedProvider>
    </ContentArea>
  );
}

function computeCardOptions() {
  if (SpecificFoundAd.isEnabled()) {
    return {
      userValue: "WORKER_BANKING_SPECIFIC_FOUND_AD",
      testID: "specific-found-card",
      title: "Create a Found Bank Account"
    };
  }

  if (GenericFoundAd.isEnabled()) {
    return {
      userValue: "WORKER_BANKING_GENERIC_FOUND_AD",
      testID: "generic-found-card",
      title: "Create a New Bank Account"
    };
  }

  return {
    userValue: "WORKER_BANKING_GENERIC_BANKING_AD",
    testID: "generic-banking-card",
    title: "GigSmart Banking Partners"
  };
}

function renderContent(screen: string, uuid = "_") {
  if (SpecificFoundAd.isEnabled() || GenericFoundAd.isEnabled()) {
    const isSpecific = SpecificFoundAd.isEnabled();
    const prefix = isSpecific ? "Found " : "";
    return (
      <Stack>
        {isSpecific ? <FoundBanner /> : <BenefitsSVG />}
        <Text color="primary" weight="bold">
          How It Works:
        </Text>
        <Stack size="compact">
          {renderDescRow("Sign up takes less than 5 minutes")}
          {renderDescRow(
            `Receive a ${prefix}virtual & physical debit card that you can use anywhere`
          )}
          {renderDescRow(
            `GigSmart will issue your pay to your new ${prefix}account`
          )}
          {renderDescRow("No account fees, no minimums and no credit checks")}
        </Stack>
        <Well color="success">
          <Text align="center">
            Receive a{" "}
            <Text weight="bold" color="success">
              $50 bonus
            </Text>{" "}
            once you deposit $500 from GigSmart into your {prefix}account.{" "}
            <Link openURL="https://found.com/legal/platform-deposit-bonus">
              Terms Apply
            </Link>
            .
          </Text>
        </Well>
        <Button
          outline
          onPress={() => {
            const url = `http://www.found.com/gigsmart?${qs.stringify({
              utm_medium: "growth_partnership",
              utm_source: "gigsmart",
              utm_campaign: `${
                isSpecific ? "specific_" : "generic_"
              }${screen.toLowerCase()}`,
              partnership_user_id: uuid
            })}`;
            void showEmbeddedBrowser({ url });
          }}
          {...(isSpecific
            ? {
                label: "Create a Found Bank Account",
                eventTargetName: "Create a Found Bank Account",
                testID: "create-found-account-btn"
              }
            : {
                label: "Create a New Bank Account",
                eventTargetName: "Create a New Bank Account",
                testID: "create-bank-account-btn"
              })}
        />
      </Stack>
    );
  }

  if (GenericBankingAd.isEnabled()) {
    return (
      <Stack>
        <BenefitsSVG height={92} />
        <Text>
          As a GigSmart Worker, you can access various financial solutions
          including free checking, flexible credit cards, high-interest savings,
          banking services, expense tracking, and simplified 1099 payments.{" "}
          <Text weight="bold">
            Signing up is easy and you may be eligible for special bonuses
            provided by our partners!
          </Text>
        </Text>
        <Button
          outline
          testID="view-banking-btn"
          label="View GigSmart Banking Partners"
          eventTargetName="View GigSmart Banking Partners"
          onPress={() => {
            void showEmbeddedBrowser({
              url: "https://gigsmart.com/find-work-banking-partners"
            });
          }}
        />
      </Stack>
    );
  }
}

function renderDescRow(content: string) {
  return (
    <Stack horizontal size="compact" alignItems="center">
      <IconCircle size="tiny" icon="check" color="success" variant="well" />
      <Text fill>{content}</Text>
    </Stack>
  );
}
