import { Column, Row } from "@gigsmart/atorasu";
import { renderWaitingStartNode } from "@gigsmart/isomorphic-shared/worker/helpers";
import { graphql, useRelayFragment } from "@gigsmart/relay";
import ShiftDescriptionItem from "@gigsmart/seibutsu/shifts/accessory/ShiftDescriptionItem";
import React from "react";
import EngagementMainAction from "./engagement-main-action";
import ProjectDescriptionItem from "./project-description-item";

import type { activeEngagementSummaryRow_engagement$key } from "./__generated__/activeEngagementSummaryRow_engagement.graphql";

interface Props {
  engagementRef: activeEngagementSummaryRow_engagement$key | null | undefined;
}

const ActiveEngagementSummaryRow = ({ engagementRef }: Props) => {
  const engagement = useRelayFragment(
    graphql`
      fragment activeEngagementSummaryRow_engagement on Engagement {
        ...ShiftDescriptionItem_shift
        ...engagementMainAction_engagement
        ...projectDescriptionItem_engagement
        ...helpersWorker_engagement
        gigType
        gig {
          ...WorkerLatestArrivalTimeReminder_gig
        }
        availableActions
      }
    `,
    engagementRef
  );
  if (!engagement) return null;

  const isProject = engagement?.gigType === "PROJECT";
  return (
    <Column gap="standard">
      <Row gap="slim">
        {isProject ? (
          <ProjectDescriptionItem engagementRef={engagement} />
        ) : (
          <ShiftDescriptionItem fill fragmentRef={engagement} />
        )}
        <EngagementMainAction engagementRef={engagement} />
      </Row>
      {renderWaitingStartNode(engagement)}
    </Column>
  );
};

export default ActiveEngagementSummaryRow;
