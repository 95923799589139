/**
 * @generated SignedSource<<022fdf615f877e8fdce2139a15c2ad9b>>
 * @relayHash 9b67c742773a5f5606ef006f18319b70
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:0rv1D6sVCVjRO_TZkmDpCOM4X0h5tb5cuCayg2H7wlc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationStaticMapQuery$variables = {
  height: number;
  id: string;
  width: number;
};
export type LocationStaticMapQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"LocationStaticMap_node">;
  } | null | undefined;
};
export type LocationStaticMapQuery = {
  response: LocationStaticMapQuery$data;
  variables: LocationStaticMapQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "height"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "width"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "height",
    "variableName": "height"
  },
  {
    "kind": "Variable",
    "name": "width",
    "variableName": "width"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationStaticMapQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "FragmentSpread",
            "name": "LocationStaticMap_node"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LocationStaticMapQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "kind": "ScalarField",
                "name": "staticMapUrl",
                "storageKey": null
              }
            ],
            "type": "OrganizationLocation",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:0rv1D6sVCVjRO_TZkmDpCOM4X0h5tb5cuCayg2H7wlc",
    "metadata": {},
    "name": "LocationStaticMapQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f95aa182399eb6feea9a4fc2295db051";

export default node;
