/**
 * @generated SignedSource<<df5c29582ff143bfb61b5602ef2f5afe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementAddonFeeType = "GENERAL_LIABILITY_INSURANCE" | "OCCUPATIONAL_ACCIDENT_INSURANCE" | "%future added value";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftDescriptionItem_shift$data = {
  readonly currentState: {
    readonly name: EngagementStateName;
    readonly transitionedAt: string;
  };
  readonly gig: {
    readonly organizationLocation: {
      readonly place: {
        readonly id: string;
        readonly location: {
          readonly latitude: number;
          readonly longitude: number;
        } | null | undefined;
        readonly streetAddress: string | null | undefined;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ParkingInfoHeaderRow_orgLocation">;
    } | null | undefined;
  };
  readonly gigSeries: {
    readonly area: string | null | undefined;
  };
  readonly id: string;
  readonly paymentInfo: {
    readonly basePay: string;
    readonly billableDuration: string;
    readonly netPay: string;
    readonly payRate: string;
    readonly serviceFees: ReadonlyArray<{
      readonly amount: string;
      readonly feeType: EngagementAddonFeeType;
      readonly hourlyRate: string | null | undefined;
    }>;
  } | null | undefined;
  readonly worker: {
    readonly transportationMethod: string | null | undefined;
  };
  readonly workerDistance: number | null | undefined;
  readonly workerEta: string | null | undefined;
  readonly workerTimesheet: {
    readonly editable: boolean;
    readonly paymentInfo: {
      readonly netPay: string;
    } | null | undefined;
    readonly totalDurationWorked: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ShiftDescriptionItem_shift";
};
export type ShiftDescriptionItem_shift$key = {
  readonly " $data"?: ShiftDescriptionItem_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDescriptionItem_shift">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPay",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftDescriptionItem_shift",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transitionedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerEta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workerDistance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worker",
      "kind": "LinkedField",
      "name": "worker",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transportationMethod",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GigSeries",
      "kind": "LinkedField",
      "name": "gigSeries",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "area",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationLocation",
          "kind": "LinkedField",
          "name": "organizationLocation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Place",
              "kind": "LinkedField",
              "name": "place",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "streetAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Location",
                  "kind": "LinkedField",
                  "name": "location",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "latitude",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "longitude",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ParkingInfoHeaderRow_orgLocation"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementPaymentInfo",
      "kind": "LinkedField",
      "name": "paymentInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billableDuration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "payRate",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkerServiceFee",
          "kind": "LinkedField",
          "name": "serviceFees",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feeType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hourlyRate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "workerTimesheet",
      "args": [
        {
          "kind": "Literal",
          "name": "variant",
          "value": "WORKER"
        }
      ],
      "concreteType": "EngagementTimesheet",
      "kind": "LinkedField",
      "name": "timesheet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "editable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalDurationWorked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EngagementPaymentInfo",
          "kind": "LinkedField",
          "name": "paymentInfo",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "timesheet(variant:\"WORKER\")"
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "0fa54ff3902b40799bb9c77f80ff3df8";

export default node;
