import Path from "path";
import ImageResizer from "@bam.tech/react-native-image-resizer";
import type { FileItem } from "@gigsmart/atorasu";
import { captureError } from "@gigsmart/dekigoto";
import mimeTypes from "mime-types";

export const pathToFile = (path: string): FileItem => {
  const uri = new URL(path, "file:");
  const type = mimeTypes.lookup(uri.pathname) || "";
  const name = Path.basename(uri.pathname ?? "");

  return { uri: path, type, name };
};

interface ResizeImageOptions {
  path: string;
  width?: number;
  height?: number;
  compressImageQuality?: number;
}
export const resizeImage = async ({
  path,
  width = 1024,
  height = 1024,
  compressImageQuality = 0.8
}: ResizeImageOptions) => {
  const result = { path, size: 0 };
  try {
    const resized = await ImageResizer.createResizedImage(
      path,
      width,
      height,
      "JPEG",
      Math.round(compressImageQuality * 100),
      0,
      undefined,
      true
    );
    result.path = resized.uri || path;
    result.size = resized.size;
  } catch (err) {
    captureError(err);
    console.error(`Error resizing image: ${path}`, err);
  }
  return result;
};
