/**
 * @generated SignedSource<<45e5607bb3760faf052bb8e685b5d20d>>
 * @relayHash 425e512316bfdddbfc8185c94c05c786
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:2K-z_o_bXOGCwb4mT2ieW3md58VmfDe55fnPXOVZw9Q

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
export type WorkerShiftTabsQuery$variables = {
  shiftId: string;
};
export type WorkerShiftTabsQuery$data = {
  readonly node: {
    readonly currentNegotiation?: {
      readonly payRate: string | null | undefined;
    } | null | undefined;
    readonly currentState?: {
      readonly name: EngagementStateName;
    };
    readonly gig?: {
      readonly organization: {
        readonly id: string;
      } | null | undefined;
      readonly organizationLocation: {
        readonly " $fragmentSpreads": FragmentRefs<"ParkingLocationCard_orgLocation">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"BlockOrganizationCollapsibleRow_shift" | "CancellationPoliciesRow_shift" | "ShiftDescriptionCard_shift" | "ShiftDetailsCard_shift" | "ShiftLocationCard_shift">;
    };
    readonly id: string;
    readonly workerDistance?: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ShiftInfoCollapsable_engagement" | "WithdrawButton_engagement">;
  } | null | undefined;
};
export type WorkerShiftTabsQuery = {
  response: WorkerShiftTabsQuery$data;
  variables: WorkerShiftTabsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shiftId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "shiftId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workerDistance",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payRate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gigType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v2/*: any*/)
],
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v12 = [
  (v2/*: any*/),
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "filename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mimeType",
    "storageKey": null
  }
],
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetAddress",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v18 = [
  (v16/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "minimumStartDelta",
    "storageKey": null
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "RemoteApplicationTag",
  "kind": "LinkedField",
  "name": "customTags",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteApplicationIcon",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        (v19/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slots",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v23 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v24 = [
  (v11/*: any*/),
  (v2/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupEligible",
  "storageKey": null
},
v26 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paySchedule",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "billableDuration",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "grossPay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPay",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "WorkerServiceFee",
    "kind": "LinkedField",
    "name": "serviceFees",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hourlyRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feeType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "EngagementPaymentInfo",
  "kind": "LinkedField",
  "name": "estimatedPayment",
  "plural": false,
  "selections": (v26/*: any*/),
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerShiftTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShiftInfoCollapsable_engagement"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WithdrawButton_engagement"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementNegotiation",
                "kind": "LinkedField",
                "name": "currentNegotiation",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "BlockOrganizationCollapsibleRow_shift",
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "type": "GigLike",
                        "abstractKey": "__isGigLike"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ShiftDescriptionCard_shift"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ShiftLocationCard_shift"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ShiftDetailsCard_shift"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CancellationPoliciesRow_shift"
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationLocation",
                    "kind": "LinkedField",
                    "name": "organizationLocation",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ParkingLocationCard_orgLocation"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerShiftTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementState",
                "kind": "LinkedField",
                "name": "currentState",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gig",
                "kind": "LinkedField",
                "name": "gig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requiredReportTypes",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationLocation",
                    "kind": "LinkedField",
                    "name": "organizationLocation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locationInstructions",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v10/*: any*/),
                        "concreteType": "OrganizationLocationAttachmentsConnection",
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationLocationAttachmentsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationLocationAttachment",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "attachments(first:50)"
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": (v13/*: any*/),
                        "concreteType": "OrganizationParkingLocationsConnection",
                        "kind": "LinkedField",
                        "name": "parkingLocations",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationParkingLocationsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationParkingLocation",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "instructions",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Place",
                                    "kind": "LinkedField",
                                    "name": "place",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": (v10/*: any*/),
                                    "concreteType": "OrganizationParkingLocationAttachmentsConnection",
                                    "kind": "LinkedField",
                                    "name": "attachments",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "OrganizationParkingLocationAttachmentsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "OrganizationParkingLocationAttachment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v12/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "attachments(first:50)"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "parkingLocations(first:1)"
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v6/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "acceptsTips",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimatedMileage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GigPosition",
                        "kind": "LinkedField",
                        "name": "position",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationLocation",
                        "kind": "LinkedField",
                        "name": "organizationLocation",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "arrivalInstructions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Place",
                            "kind": "LinkedField",
                            "name": "place",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v17/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Crisis",
                        "kind": "LinkedField",
                        "name": "crisis",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RequesterCancellationPolicy",
                        "kind": "LinkedField",
                        "name": "requesterCancellationPolicies",
                        "plural": true,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkerCancellationPolicy",
                        "kind": "LinkedField",
                        "name": "workerCancellationPolicies",
                        "plural": true,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "alias": null,
                            "args": (v23/*: any*/),
                            "concreteType": "GigPhotosConnection",
                            "kind": "LinkedField",
                            "name": "photos",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GigPhotosEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "GigPhoto",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v24/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "photos(first:10)"
                          },
                          (v5/*: any*/),
                          (v25/*: any*/)
                        ],
                        "type": "Gig",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v21/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "boosted",
                            "storageKey": null
                          },
                          (v22/*: any*/),
                          (v20/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationPosition",
                            "kind": "LinkedField",
                            "name": "organizationPosition",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": (v23/*: any*/),
                                "concreteType": "OrganizationPositionPhotosConnection",
                                "kind": "LinkedField",
                                "name": "photos",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrganizationPositionPhotosEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "OrganizationPositionPhoto",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v24/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "photos(first:10)"
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "nextGig",
                            "args": (v13/*: any*/),
                            "concreteType": "AvailableGigsConnection",
                            "kind": "LinkedField",
                            "name": "availableGigs",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AvailableGigsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "distance",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v27/*: any*/)
                                        ],
                                        "type": "AvailableGigsEdge",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v27/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "EngagementPaymentInfo",
                                            "kind": "LinkedField",
                                            "name": "paymentInfo",
                                            "plural": false,
                                            "selections": (v26/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "Engagement",
                                        "abstractKey": null
                                      }
                                    ],
                                    "type": "AvailableGigOrEngagement",
                                    "abstractKey": "__isAvailableGigOrEngagement"
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Gig",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v25/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "availableGigs(first:1)"
                          }
                        ],
                        "type": "GigSeries",
                        "abstractKey": null
                      }
                    ],
                    "type": "GigLike",
                    "abstractKey": "__isGigLike"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Worker",
                "kind": "LinkedField",
                "name": "worker",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkerVerification",
                    "kind": "LinkedField",
                    "name": "verification",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VerificationReport",
                        "kind": "LinkedField",
                        "name": "reports",
                        "plural": true,
                        "selections": [
                          (v28/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "availableActions",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementCapability",
                "kind": "LinkedField",
                "name": "capabilities",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "restrictedBy",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v28/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngagementNegotiation",
                "kind": "LinkedField",
                "name": "currentNegotiation",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Engagement",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:2K-z_o_bXOGCwb4mT2ieW3md58VmfDe55fnPXOVZw9Q",
    "metadata": {},
    "name": "WorkerShiftTabsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "04037ee183914a60432806e7704dd763";

export default node;
