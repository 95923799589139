/**
 * @generated SignedSource<<1c556aaa7fa46f2ae91feec6eca288b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EngagementStateName = "APPLICATION_CANCELED" | "APPLICATION_DENIED" | "APPLIED" | "APPLIED_UNAVAILABLE" | "AWAITING_START" | "BID_REQUESTED" | "BID_REVIEW" | "CANCELED" | "CANCELED_WITH_PAY" | "CONFIRMING" | "DISBURSED" | "ENDED" | "ENGAGED" | "ENGAGED_APPLICATION_DENIED" | "EN_ROUTE" | "HIRE_REQUESTED" | "INITIAL" | "MISSED" | "OFFERED" | "PAID" | "PAUSED" | "PAUSE_REQUESTED" | "PAYMENT_FAILED" | "PENDING_REVIEW" | "PENDING_TIMESHEET_APPROVAL" | "PRE_APPROVAL" | "PRE_APPROVAL_CANCELED" | "PRE_APPROVAL_MISSED" | "PRE_APPROVAL_REJECTED" | "REJECTED" | "RUNNING_LATE" | "SCHEDULED" | "UNAVAILABLE" | "WORKING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActiveShiftHeader_engagement$data = {
  readonly commitment: {
    readonly " $fragmentSpreads": FragmentRefs<"ShiftCommitmentReminder_commitment">;
  } | null | undefined;
  readonly currentState: {
    readonly name: EngagementStateName;
  };
  readonly gig: {
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ShiftGroupChatActionRow_gig">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ShiftActions_shift" | "ShiftDescriptionHeader_availableGig" | "ShiftDescriptionItem_shift" | "ShiftOrgActionRow_engagement" | "ShiftStatusTags_shift" | "SingleShiftSummary_engagement" | "WorkerShiftApplets_shift" | "WorkerShiftStatusText_shift">;
  readonly " $fragmentType": "ActiveShiftHeader_engagement";
};
export type ActiveShiftHeader_engagement$key = {
  readonly " $data"?: ActiveShiftHeader_engagement$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveShiftHeader_engagement">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveShiftHeader_engagement",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftOrgActionRow_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SingleShiftSummary_engagement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftStatusText_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftStatusTags_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftDescriptionHeader_availableGig"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftActions_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftDescriptionItem_shift"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkerShiftApplets_shift"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementState",
      "kind": "LinkedField",
      "name": "currentState",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Gig",
      "kind": "LinkedField",
      "name": "gig",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShiftGroupChatActionRow_gig"
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngagementCommitment",
      "kind": "LinkedField",
      "name": "commitment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ShiftCommitmentReminder_commitment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Engagement",
  "abstractKey": null
};
})();

(node as any).hash = "1f44bc930e91ebb3498aa387727b8768";

export default node;
