import { Platform } from "react-native";
import { FlagType, registerSection } from "./registry";

let isDev = false;

if (typeof __DEV__ !== "undefined") {
  isDev = __DEV__;
}

const REMOTE_DEBUG_WHEN_LOCAL =
  isDev || process.env.CONFIG_ENV === "local"
    ? FlagType.DEBUG
    : FlagType.REMOTE;

const REMOTE_PINNED_WHEN_LOCAL =
  isDev || process.env.CONFIG_ENV === "local"
    ? FlagType.PINNED
    : FlagType.REMOTE;

const REMOTE_PINNED_WHEN_TESTING =
  process.env.IS_TESTING === "true" ? FlagType.PINNED : FlagType.REMOTE;

// PINNED FLAGS
// Flags that have been fully released into production

// Active Development Flags
// These flags are for features that are in active development
const UI = registerSection("UI Refresh");
export const WorkerShiftCardUI = UI.registerFlag("worker-shift-card-ui", {
  type: FlagType.PINNED
});

const SCFU = registerSection("Service Credit Union");
export const SFCUGeneric = SCFU.registerFlag("SFCU-generic-modal");
export const SFCUBranded = SCFU.registerFlag("SFCU-branded-modal");

const Ad = registerSection("Ad");
export const GenericHoorayAd = Ad.registerFlag("generic-hooray-ad");
export const BrandedHoorayAd = Ad.registerFlag("branded-hooray-ad");

const Banking = registerSection("Found & Banking Partnership Ads");
export const SpecificFoundAd = Banking.registerFlag("specific-found-ad");
export const GenericFoundAd = Banking.registerFlag("generic-found-ad");
export const GenericBankingAd = Banking.registerFlag("generic-banking-ad");

const Enterprise = registerSection("Enterprise");
export const EnterpriseSwitchToPersonal = Enterprise.registerFlag(
  "enterprise-switch-to-personal"
);

const ShiftManagement = registerSection("Shift Management");
export const ShiftsList = ShiftManagement.registerFlag("shifts-list");
export const DirectOfferMultipleShifts = ShiftManagement.registerFlag(
  "direct-offer-multiple-shifts"
);
export const WorkerExcusedAbsences = ShiftManagement.registerFlag(
  "worker-excused-absences"
);
export const AutoCounterOfferSetting = ShiftManagement.registerFlag(
  "auto-counter-offer-setting"
);
export const DirectPaymentsOnly = ShiftManagement.registerFlag(
  "direct-payments-only"
);
export const ShiftManagementRedesign = ShiftManagement.registerFlag(
  "shift-manage-redesign",
  { type: FlagType.PINNED }
);
export const DirectOffer = ShiftManagement.registerFlag("direct-offer", {
  type: FlagType.PINNED
});
export const RequesterCalendar = ShiftManagement.registerFlag(
  "requester-calendar",
  { type: FlagType.PINNED }
);
export const ReqShiftList = ShiftManagement.registerFlag("req-shift-list", {
  type: FlagType.PINNED
});
export const ReqShiftFilters = ShiftManagement.registerFlag(
  "req-shift-filters",
  { type: FlagType.PINNED }
);
export const WorkerEngagementView = ShiftManagement.registerFlag(
  "worker-engagement-view",
  { type: FlagType.PINNED }
);
export const HourlyRateBids = ShiftManagement.registerFlag("hourly-rate-bids", {
  capability: true,
  type: FlagType.PINNED
});
export const BypassCalifornia8HourShiftMax = ShiftManagement.registerFlag(
  "bypass-california-8-hour-shift-max"
);

const MessagingAndNotifications = registerSection(
  "Messaging and Notifications"
);
export const BulkMessageCenter = MessagingAndNotifications.registerFlag(
  "bulk-message-center"
);
export const OperatorCalling =
  MessagingAndNotifications.registerFlag("operator-calling");
export const ConversationTopics = MessagingAndNotifications.registerFlag(
  "conversation-topics",
  { capability: true, type: FlagType.PINNED }
);
export const MessagingParticipantSearch =
  MessagingAndNotifications.registerFlag("messaging-participant-search", {
    type: FlagType.PINNED
  });

export const Onboarding = registerSection("Onboarding");
export const TwilioConsentOnboarding = Onboarding.registerFlag(
  "twilio-consent-onboarding",
  { type: FlagType.PINNED }
);

const SmartHire = registerSection("Smart Hire");
export const AutoBackfillWorkers = SmartHire.registerFlag(
  "auto-backfill-workers"
);
export const MandatoryAutohire = SmartHire.registerFlag("mandatory-auto-hire", {
  capability: true,
  type: FlagType.PINNED
});
export const MandatoryAutohireShifts = SmartHire.registerFlag(
  "mandatory-auto-hire-shifts",
  {
    capability: true,
    dependsOn: MandatoryAutohire,
    type: FlagType.PINNED
  }
);

export const ManuallyTriggerAutohire = SmartHire.registerFlag(
  "manually-trigger-auto-hire",
  {
    dependsOn: MandatoryAutohire,
    type: FlagType.PINNED
  }
);

export const AlwaysBlindAutohire = SmartHire.registerFlag(
  "always-blind-auto-hire",
  {
    dependsOn: MandatoryAutohire,
    capability: true
  }
);

export const ManuallyTriggerBlindAutohire = SmartHire.registerFlag(
  "manually-trigger-blind-auto-hire",
  {
    dependsOn: ManuallyTriggerAutohire
  }
);

export const Account = registerSection("Account");
export const RequesterAccountApproval = Account.registerFlag(
  "requester-account-approval",
  { type: FlagType.PINNED }
);
export const BlockWorkers = Account.registerFlag("block-workers", {
  type: FlagType.PINNED
});
export const CustomWorkerGroups = Account.registerFlag("custom-worker-groups", {
  type: FlagType.PINNED
});
export const ReqFavoritesAsGroup = Account.registerFlag(
  "req-favorites-as-group",
  {
    capability: true
  }
);
export const WorkerProfileRefresh = Account.registerFlag(
  "worker-profile-refresh",
  {
    type: FlagType.PINNED
  }
);

export const NewGwOnboarding = Account.registerFlag("new-gw-onboarding", {
  capability: true
});

export const RequiresOTP = Account.registerFlag("requires-otp-login", {
  capability: true
});

export const OrganizationSmsConfirmationSettings = Account.registerFlag(
  "organization-sms-confirmation-settings"
);
export const WorkerBlockOrg = Account.registerFlag("worker-block-org");

export const BenefitPlansOnboardingModal = Onboarding.registerFlag(
  "benefit-plans-onboarding-modal"
);

export const Updates = registerSection("Updates");
export const OauthApplications = Enterprise.registerFlag("oauth-applications");
export const NudgeUpdateFeature = Updates.registerFlag("nudge-update", {
  type: REMOTE_DEBUG_WHEN_LOCAL
});

export const Announcements = registerSection("Announcements");
export const AnnouncementsV2 = Announcements.registerFlag("announcements-v2", {
  type: FlagType.PINNED
});
export const CrisisGigs = ShiftManagement.registerFlag("crisis-gigs", {
  type: FlagType.PINNED
});
export const NotificationsInConversation =
  MessagingAndNotifications.registerFlag("notifications-in-conversation");
export const ConversationFullTimestamps =
  MessagingAndNotifications.registerFlag("conversation-full-timestamps");
export const Browse = registerSection("Browse");
export const ShiftGigsMapScreen = Browse.registerFlag("shift-gigs-map");
export const CorrectAppModalFeature = Onboarding.registerFlag(
  "correct-app-modal",
  { type: Platform.OS === "web" ? FlagType.PINNED : undefined }
);
export const AppleSigninAndroidFeature = Onboarding.registerFlag(
  "apple-signin-android"
);
export const SocialSigninAppleDisabled = Onboarding.registerFlag(
  "social-signin-apple-disabled"
);
export const SocialSigninFacebookDisabled = Onboarding.registerFlag(
  "social-signin-facebook-disabled"
);
export const SocialSigninGoogleDisabled = Onboarding.registerFlag(
  "social-signin-google-disabled"
);

export const SmartHireConsent = SmartHire.registerFlag("smart-hire-consent", {
  type: FlagType.PINNED
});

/**
 * Engagemnet Flow Section
 */
export const EngagementFlow = registerSection("Engagement Flow");
export const WorkerEtaCalculator = EngagementFlow.registerFlag(
  "worker-ETA-calculator",
  { type: FlagType.PINNED }
);
export const MandatoryApplicationQuestionnaire = EngagementFlow.registerFlag(
  "mandatory-application-questionnaire"
);
export const RefreshLocationFeature = EngagementFlow.registerFlag(
  "refresh-location",
  { type: FlagType.PINNED }
);
export const PendingTimesheetApprovalView = EngagementFlow.registerFlag(
  "pending-timesheet-approval-view",
  { type: FlagType.PINNED }
);
export const PendingTimesheetApprovalViewNew = EngagementFlow.registerFlag(
  "pending-timesheet-approval-view-new",
  { type: FlagType.PINNED }
);
export const PendingTimesheetApprovalWorker = EngagementFlow.registerFlag(
  "pending-timesheet-approval-worker"
);
export const WorkerLocationTracking = EngagementFlow.registerFlag(
  "worker-location-tracking"
);

/**
 * Payments Section
 */
const Payments = registerSection("Payments");
export const ApplePay = Payments.registerFlag("apple-pay");
export const GooglePay = Payments.registerFlag("google-pay");

/**
 * GigCreation Section
 */
const GigCreation = registerSection("Gig Creation");
export const ExcludeWorkerGroups = GigCreation.registerFlag(
  "exclude-worker-groups"
);
export const DisableInviteOnlyGigs = GigCreation.registerFlag(
  "disable-invite-only-gigs"
);
export const ProjectGigsPayInApp = GigCreation.registerFlag(
  "project-gigs-pay-in-app",
  { capability: true }
);
export const GigBoost = GigCreation.registerFlag("gig-boost", {
  type: FlagType.PINNED,
  capability: true
});
export const MileageTracking = GigCreation.registerFlag("mileage-tracking", {
  type: FlagType.PINNED
});
export const AcceptsTips = GigCreation.registerFlag("accepts-tips", {
  type: FlagType.PINNED
});
export const PositionsLocationNudge = GigCreation.registerFlag(
  "positions-locations-nudge"
);

const Benefits = registerSection("Benefits");
export const RequesterBenefits = Benefits.registerFlag("requester-benefits");
export const RemoveBenefitsMenu = Benefits.registerFlag("remove-benefits-menu");
export const SearchFilters = Browse.registerFlag("search-filters", {
  type: FlagType.PINNED
});
const App = registerSection("Application");
export const FreshLook = App.registerFlag("fresh-look");
export const EditGeofence = App.registerFlag("geofence-edits");
export const PolygonGeofence = App.registerFlag("polygon-geofence");
export const OrgProfileRedesign = App.registerFlag("org-profile-redesign", {
  type: FlagType.PINNED
});
export const OrgLocations = App.registerFlag("org-locations", {
  type: FlagType.PINNED
});
export const PositionsAndLocations = App.registerFlag("positions-locations", {
  capability: true,
  type: FlagType.PINNED
});
export const UpdatePosition = App.registerFlag("update-position");
export const WorkerBottomNavRefresh = App.registerFlag(
  "worker-bottom-nav-refresh",
  { type: FlagType.PINNED }
);
export const FavoritesAsGroups = App.registerFlag("req-favorites-as-groups", {
  capability: true
});
export const WorkerApproveTimesheet = EngagementFlow.registerFlag(
  "worker-approve-system-timesheet",
  { capability: true, type: FlagType.PINNED }
);
export const GigFieldsAttireTools = GigCreation.registerFlag(
  "gig-fields-attire-tools",
  {
    type: FlagType.PINNED
  }
);
export const TimesheetDisputes = EngagementFlow.registerFlag(
  "timesheet-disputes",
  { capability: true, type: FlagType.PINNED }
);
const DirectHireSection = registerSection("Direct Hire");
export const DirectHire = DirectHireSection.registerFlag("direct-hire", {
  type: FlagType.PINNED
});
export const GigAudiences = GigCreation.registerFlag("gig-audiences", {
  type: FlagType.PINNED
});
export const WysiwygShiftGig = GigCreation.registerFlag(
  "wysiwyg-shift-gig",
  process.env.IS_TESTING ? undefined : { type: FlagType.PINNED }
);
export const WysiwygProjectGigDisabled = GigCreation.registerFlag(
  "wysiwyg-project-gig-disabled"
);

export const BulkTimesheetReview = EngagementFlow.registerFlag(
  "bulk-timesheet-review",
  {
    type: FlagType.PINNED
  }
);
export const WorkerHomeScreenRefresh = App.registerFlag(
  "worker-homescreen-refresh",
  { type: FlagType.PINNED }
);
export const DirectHirePolicyAgreement = DirectHireSection.registerFlag(
  "direct-hire-policy-agreement",
  {
    type: FlagType.PINNED
  }
);
export const ShiftRoster = ShiftManagement.registerFlag("shift-roster", {
  type: FlagType.PINNED
});
const Architecture = registerSection("Architecture");
export const DisableFpjs = Architecture.registerFlag("disable-fpjs");
export const BrowserTitleChange = Architecture.registerFlag(
  "browser-title-change"
);
const JobPosts = registerSection("Job Posts");
export const UpwardJobPosts = JobPosts.registerFlag("upward-job-posts", {
  capability: true
});
export const TalrooJobPosts = JobPosts.registerFlag("talroo-job-posts", {
  capability: true
});
export const DisableJobPostsWysiwyg = JobPosts.registerFlag(
  "disable-job-posts-wysiwyg"
);
export const ShiftGigDateExtension = GigCreation.registerFlag(
  "shift-gig-date-extension"
);
export const VehicleTypeSelection = Onboarding.registerFlag(
  "vehicle-type-selection",
  {
    capability: true,
    type: FlagType.PINNED
  }
);
export const RequesterWorkerListView = ShiftManagement.registerFlag(
  "requester-worker-list-view",
  { type: FlagType.PINNED }
);
export const RequesterUpdatedOrganizationOnboarding = Onboarding.registerFlag(
  "requester-updated-organization-onboarding",
  { capability: true, type: FlagType.PINNED }
);
const Relay = registerSection("Relay");
export const CompressCapabilities = Relay.registerFlag(
  "compress-capabilities",
  {
    type: REMOTE_PINNED_WHEN_TESTING
  }
);
export const RelayDisableRefetchOnFocus = Relay.registerFlag(
  "relay-disable-refetch-on-focus",
  { type: FlagType.DEBUG }
);
export const RelayDisableSuspenseFallbackWaiting = Relay.registerFlag(
  "relay-disable-suspense-fallback-waiting",
  { type: FlagType.DEBUG }
);
export const RelayDisableInSuspense = Relay.registerFlag(
  "relay-disable-in-suspense",
  { type: FlagType.DEBUG }
);
const Applets = registerSection("Applets");
export const ThirdPartyApplications = Applets.registerFlag(
  "third-party-applications",
  {
    capability: true
  }
);
export const OnfleetIntegration = Applets.registerFlag("onfleet-integration", {
  dependsOn: ThirdPartyApplications,
  capability: true
});
export const OnfleetDispatcher = Applets.registerFlag("onfleet-dispatcher", {
  dependsOn: OnfleetIntegration
});
export const CategoriesPositionsQualifications = GigCreation.registerFlag(
  "categories-positions-qualifications",
  { capability: true, type: FlagType.PINNED }
);
export const MaximumWorkerSlotsTwo = GigCreation.registerFlag(
  "maximum-worker-slots-two"
);
export const MaximumWorkerSlots750 = GigCreation.registerFlag(
  "maximum-worker-slots-750"
);
export const EORWorkers = GigCreation.registerFlag("eor-workers", {
  capability: true
});
export const BulkMessageShift = MessagingAndNotifications.registerFlag(
  "bulk-message-shift",
  { type: FlagType.PINNED }
);
export const RecurringSchedule = GigCreation.registerFlag(
  "recurring-schedule",
  { type: FlagType.PINNED }
);
export const AddBankAccount = Payments.registerFlag("add-bank-account");
export const MessagingRefresh = MessagingAndNotifications.registerFlag(
  "messaging-refresh",
  { capability: true, type: FlagType.PINNED }
);
export const ShiftGroupChat = MessagingAndNotifications.registerFlag(
  "shift-group-chat",
  { capability: true }
);
export const GenericConsents = App.registerFlag("generic-consents");
export const MinimumAge18 = App.registerFlag("minimum-age-18");
export const WebsiteSupport = App.registerFlag("website-support");

// Event Flags
const Telemetry = registerSection("Telemetry");
export const DisableSentry = Telemetry.registerFlag("disable-sentry");
export const DisableListenLayer = Telemetry.registerFlag(
  "disable-listen-layer"
);
export const DisableRudderStack = Telemetry.registerFlag(
  "disable-ruddersrtack",
  {
    capability: true
  }
);
export const DisableLifecycleEvents = Telemetry.registerFlag(
  "disable-lifecycle-events"
);
export const DisableUtmTracking = Telemetry.registerFlag(
  "disable-utm-tracking"
);
export const DisableInstallEvents = Telemetry.registerFlag(
  "disable-install-events"
);
export const DisableMixpanel = Telemetry.registerFlag("disable-mixpanel");
export const DisableFirebaseAnalytics = Telemetry.registerFlag(
  "disable-firebase-analytics"
);
export const ReportAlias = Telemetry.registerFlag("report-alias");
export const ZeroMinimumHourlyRate = GigCreation.registerFlag(
  "0-minimum-hourly-rate",
  {
    capability: true
  }
);

// UNPINNABLE FLAGS
// NEVER PIN THESE IN CODE
export const DisableMap = Architecture.registerFlag("disable-map");
export const ForceUpgradeFeature = Updates.registerFlag("force-upgrade", {
  type: REMOTE_DEBUG_WHEN_LOCAL
});
export const UndergoingMaintenanceFeature = Architecture.registerFlag(
  "undergoing-maintenance",
  { type: REMOTE_DEBUG_WHEN_LOCAL }
);
export const ArrivalInstructions = GigCreation.registerFlag(
  "arrival-instructions",
  { type: FlagType.PINNED }
);
export const JobPostsMobileDisable = JobPosts.registerFlag(
  "job-posts-mobile-disable"
);

const LocationServices = registerSection("Location Services");
LocationServices.registerFlag("background-location-polygon-support", {
  capability: true
});

LocationServices.registerFlag("background-geolocation-odometer", {
  capability: true
});
export const LocationDebug = LocationServices.registerFlag("location-debug", {
  type: FlagType.DEBUG
});
export const LocationOnNotification = LocationServices.registerFlag(
  "location-on-notification",
  { type: FlagType.PINNED }
);
export const LocationForceEnable = LocationServices.registerFlag(
  "location-force-enable",
  { capability: true, type: FlagType.DEBUG }
);

export const LocationUpdates = LocationServices.registerFlag(
  "location-updates",
  { type: FlagType.DEBUG }
);
export const LocationHistory =
  LocationServices.registerFlag("location-history");

// DEBUG FLAGS
export const SentryTraceAll = Telemetry.registerFlag(
  "sentry-trace-all-transactions",
  {
    type: process.env.CONFIG_ENV === "prod" ? FlagType.REMOTE : FlagType.PINNED
  }
);

const Utils = registerSection("Utils");
export const DeveloperDebug = Utils.registerFlag("developer-debug", {
  type: FlagType.DEBUG
});
export const QAMode = Utils.registerFlag("qa-mode", {
  type: FlagType.DEBUG,
  inherits: DeveloperDebug
});
export const ViewInAdminFeature = Utils.registerFlag("view-in-admin", {
  type: FlagType.DEBUG
});
export const UrlDebugFeature = Utils.registerFlag("url-debug", {
  type: FlagType.DEBUG,
  inherits: DeveloperDebug
});
export const UnlimitedDistanceFeature = Browse.registerFlag(
  "unlimited-distance",
  {
    capability: true,
    inherits: QAMode
  }
);
export const AppletsLocalDebug = Utils.registerFlag("applets-local-debug", {
  type: FlagType.DEBUG,
  inherits: DeveloperDebug
});
export const RelationshipStrengthDebug = Utils.registerFlag(
  "relationship-strength-debug",
  {
    type: FlagType.DEBUG,
    inherits: DeveloperDebug
  }
);
export const ModalDebug = Utils.registerFlag("modal-debug", {
  type: FlagType.DEBUG,
  inherits: DeveloperDebug
});
export const SentryDebug = Utils.registerFlag("sentry-debug", {
  inherits: DeveloperDebug
});
export const ShortGig = GigCreation.registerFlag("short-gig", {
  type: FlagType.DEBUG
});
export const SentryDebugButtons = Utils.registerFlag("sentry-debug-buttons", {
  type: FlagType.DEBUG
});
export const FeaturesDebugFeature = Utils.registerFlag("feature-debug", {
  type: REMOTE_PINNED_WHEN_LOCAL,
  capability: false
});
export const DisableErrorReporting = Telemetry.registerFlag(
  "disable-error-reporting"
);
export const DisableRudderStackFirehoseEvents = Telemetry.registerFlag(
  "disable-rudderstack-firehose-events",
  { type: FlagType.PINNED }
);
export const DisableRudderStackScreen = Telemetry.registerFlag(
  "disable-rudderstack-screen",
  { type: FlagType.PINNED }
);
export const RelaySessionPinning = Relay.registerFlag("relay-session-pinning", {
  type: REMOTE_PINNED_WHEN_TESTING
});
export const RelayEtagCache = Relay.registerFlag("relay-etag-cache", {
  type: REMOTE_PINNED_WHEN_TESTING
});
export const DisableRelaySubscriptions = Relay.registerFlag(
  "disable-relay-subscriptions",
  { type: FlagType.DEBUG }
);
export const RefreshOnNavigate = Architecture.registerFlag(
  "refresh-on-navigate"
);

export const DebugRenderCycles = Architecture.registerFlag(
  "debug-render-cycles",
  { type: FlagType.DEBUG }
);
export const SignUpGetGigs = Onboarding.registerFlag("sign-up-get-gigs", {
  type: FlagType.PINNED
});
export const WorkerOnboardingStepper = Onboarding.registerFlag(
  "worker-onboarding-stepper",
  { type: FlagType.PINNED }
);
export const RequesterOnboardingStepper = Onboarding.registerFlag(
  "requester-onboarding-stepper",
  { type: FlagType.PINNED }
);
export const ApplySkillStepUpdate = EngagementFlow.registerFlag(
  "apply-skill-step-update",
  { type: FlagType.PINNED }
);
export const RequesterNavRefresh = App.registerFlag("gw-nav-refresh", {
  type: FlagType.PINNED
});
export const RequesterNewHomescreen = App.registerFlag("gw-new-homescreen", {
  type: FlagType.PINNED
});
export const LatestArrivalTime = GigCreation.registerFlag(
  "latest-arrival-time",
  {
    capability: true,
    type: FlagType.PINNED
  }
);

export const WorkerReapplyToEngagements = EngagementFlow.registerFlag(
  "worker-reapply-to-engagements",
  { capability: true, type: FlagType.PINNED }
);

export const WorkerDrugScreenings = GigCreation.registerFlag(
  "worker-drug-screenings",
  {
    capability: true,
    type: FlagType.PINNED
  }
);

export const PickupShifts = GigCreation.registerFlag("pickup-shifts", {
  capability: true,
  type: FlagType.PINNED
});

// Legacy flags (used for capabilities)
MessagingAndNotifications.registerFlag("in-app-messaging", {
  type: FlagType.PINNED,
  capability: true
});
GigCreation.registerFlag("project-gigs", {
  type: FlagType.PINNED,
  capability: true
});
GigCreation.registerFlag("verified-gigs", {
  type: FlagType.PINNED,
  capability: true
});
SmartHire.registerFlag("predictive-auto-hire", {
  type: FlagType.PINNED,
  capability: true
});
GigCreation.registerFlag("gli-insurance-addon", {
  type: FlagType.PINNED,
  capability: true
});
Architecture.registerFlag("multiple-devices", {
  type: FlagType.PINNED,
  capability: true
});
const Geocoding = registerSection("Geocoding");
GigCreation.registerFlag("oa-insurance-addon", {
  type: FlagType.PINNED,
  capability: true
});
GigCreation.registerFlag("eor-workers", {
  capability: true
});
Payments.registerFlag("new-payments", {
  type: FlagType.PINNED,
  capability: true
});
Payments.registerFlag("worker-wallet", {
  type: FlagType.PINNED,
  capability: true
});

// Capability only flags
MessagingAndNotifications.registerFlag("engagement-commitments", {
  capability: true,
  type: FlagType.PINNED
});

App.registerFlag("account-delinquent", { capability: true });
const Legal = registerSection("Legal");

export const IndependentContractorAgreement = Legal.registerFlag(
  "independent-contractor-agreement",
  { capability: true, type: FlagType.PINNED }
);

ShiftManagement.registerFlag("undo-actions", { capability: true });

const Positions = registerSection("Positions");
export const PositionsWysiwygDisabled = Positions.registerFlag(
  "positions-wysiwyg-disabled"
);

const Reporting = registerSection("In App Reporting");
export const PDFDownload = Reporting.registerFlag("pdf-download");
export const BulkPDFDownload = Reporting.registerFlag("bulk-pdf-download", {
  dependsOn: PDFDownload
});
App.registerFlag("background-location-polygon-support", { capability: true });
