import {
  Button,
  Card,
  ContentArea,
  DateTimeInputRow,
  Divider,
  Row,
  Spacer,
  Text
} from "@gigsmart/atorasu";
import { KatanaSwitch, confirmPrompt } from "@gigsmart/katana";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  graphql
} from "@gigsmart/relay";
import { findLast } from "lodash";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import {
  createEndTimeGigDurationValidator,
  createEndTimeValidator,
  createStartTimeValidator,
  useTimesheetInputFields
} from "./timesheetEditHelpers";

import type { EditTimesheetTimeWorkedCard_engagement$key } from "./__generated__/EditTimesheetTimeWorkedCard_engagement.graphql";

interface Props {
  engagementStartTime: DateTime | null;
  initialStartTime: DateTime;
  initialEndTime: DateTime;
  onRemoveTimeWorked?: () => void;
  breaks?: number[];
  allowReset?: boolean;
  title: string;
  appVariant: "requester" | "worker";
  minimumPaidGigDuration?: string | null;
}

export const EditTimesheetTimeWorkedCard = ({
  engagementStartTime,
  initialStartTime,
  initialEndTime,
  timesheetVariant,
  systemTimesheet,
  onRemoveTimeWorked,
  minimumPaidGigDuration,
  breaks,
  title,
  allowReset,
  appVariant,
  timezone
}: FragmentContainerInnerComponentProps<
  EditTimesheetTimeWorkedCard_engagement$key,
  Props
>) => {
  const isSameDay = initialStartTime.hasSame(initialEndTime, "day");
  const [showDate, setShowDate] = useState(!isSameDay);

  // Compute available dates/initial gig startTime
  const { availableDates } = useMemo(() => {
    const availableDates = [
      initialStartTime.setZone(timezone ?? undefined).startOf("day"),
      initialStartTime.plus({ days: 1 }).startOf("day")
    ];
    if (initialStartTime.minus({ hours: 3 }).hasSame(initialStartTime, "day")) {
      availableDates.unshift(initialStartTime.minus({ days: 1 }));
    }
    return { availableDates };
  }, [initialStartTime]);

  // Compute validators
  const { startTimeValidator, endTimeValidator } = useMemo(() => {
    // Fallback to system's
    const timesheetEdges = (timesheetVariant ?? systemTimesheet)?.states?.edges;
    const scheduledState = findLast(
      timesheetEdges,
      (d) => d?.node?.name === "SCHEDULED"
    )?.node;

    const relativeStartTime = (engagementStartTime ?? initialStartTime).setZone(
      timezone ?? undefined
    );
    let earliestStartTime = relativeStartTime
      .setZone(timezone ?? undefined)
      .minus({ hours: 3 });
    let showScheduledError = false;
    if (
      scheduledState?.transitionedAt &&
      DateTime.fromISO(scheduledState.transitionedAt) > earliestStartTime
    ) {
      showScheduledError = true;
      earliestStartTime = DateTime.fromISO(scheduledState.transitionedAt);
    }

    const startTimeValidator = createStartTimeValidator({
      earliestStartTime,
      dateTimeFieldName: "startTime",
      showScheduledError,
      breaks,
      appVariant,
      timezone
    });

    const endTimeValidator = [
      createEndTimeValidator({
        dateTimeFieldName: "startTime",
        breaks,
        timezone
      }),
      createEndTimeGigDurationValidator({
        dateTimeFieldName: "endTime",
        timezone,
        breaks,
        minimumPaidGigDuration
      })
    ];

    return {
      startTimeValidator,
      endTimeValidator,
      scheduledAt: scheduledState?.transitionedAt ?? initialStartTime.toISO()
    };
  }, [
    appVariant,
    breaks,
    engagementStartTime,
    initialStartTime,
    systemTimesheet,
    timesheetVariant
  ]);

  const startTimeProps = useTimesheetInputFields(
    "startTime",
    startTimeValidator
  );
  const endTimeProps = useTimesheetInputFields("endTime", endTimeValidator);

  const handleRemoveTimeWorked = () => {
    confirmPrompt({
      title: "Are you sure you want to Remove Time Worked?",
      subTitle:
        "By removing time worked, you are indicating you did not work on this Shift.",
      yesLabel: "Remove Time Worked",
      yesLabelStyle: "destructive",
      cancelLabel: "Cancel",
      onDo: onRemoveTimeWorked
    });
  };

  return (
    <Card
      testID="edit-timesheet-time-worked-card"
      eventTargetName="Edit Timesheet Time Worked Card"
    >
      <ContentArea>
        <Text weight="bold" testID="edit-timesheet-time-worked-title">
          {title}
        </Text>
        <Spacer />
        <Text>
          All times are shown in the Shift's local time:{" "}
          {DateTime.local()
            .setZone(timezone ?? undefined)
            .toFormat("ZZZZ")}
        </Text>
        <Spacer />
        <Divider />
        <DateTimeInputRow
          zIndex={20}
          {...startTimeProps}
          inputTestId="start-time"
          label="Start Time"
          availableDates={showDate ? availableDates : undefined}
        />
        <Divider />
        <DateTimeInputRow
          {...endTimeProps}
          inputTestId="end-time"
          label="End Time"
          availableDates={showDate ? availableDates : undefined}
        />
        <Divider />
        <Spacer />
        <ContentArea size="none">
          <Row alignItems="center" justifyContent="space-between">
            <Text>View dates for Start and End Times</Text>
            <KatanaSwitch
              testID="edit-timesheet-time-worked-day-switch"
              disabled={!isSameDay}
              value={showDate}
              onValueChange={setShowDate}
            />
          </Row>
          {allowReset && <Spacer />}
          {allowReset && (
            <Button
              testID="remove-time-worked-button"
              outline
              icon="redo"
              label="Remove Time Worked"
              onPress={handleRemoveTimeWorked}
            />
          )}
        </ContentArea>
      </ContentArea>
    </Card>
  );
};

export default createRelayFragmentContainer<
  EditTimesheetTimeWorkedCard_engagement$key,
  Props
>(
  graphql`
    fragment EditTimesheetTimeWorkedCard_engagement on Engagement
    @argumentDefinitions(
      variant: { type: "EngagementTimesheetVariant" }
      overridden: { type: "Boolean" }
    ) {
      timezone
      systemTimesheet: timesheet(variant: SYSTEM) {
        states(first: 50, query: "ORDER BY transitionedAt ASC") {
          edges {
            node {
              transitionedAt
              name
            }
          }
        }
      }
      timesheetVariant: timesheet(variant: $variant) {
        states(
          first: 50
          overridden: $overridden
          query: "ORDER BY transitionedAt ASC"
        ) {
          edges {
            node {
              transitionedAt
              name
            }
          }
        }
      }
    }
  `,
  EditTimesheetTimeWorkedCard
);
