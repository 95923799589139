/**
 * @generated SignedSource<<fb149a040d35fcfcbb63595af11edfa3>>
 * @relayHash f001b1ffb2194230554af6be463960ac
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID qpid:NJg6gpXtD1NqMVq-uhtg-rrEkAQpeAOsZdmvyR91JZc

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationStaticMapSubscription$variables = {
  height: number;
  id: string;
  width: number;
};
export type LocationStaticMapSubscription$data = {
  readonly nodeUpdated: {
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"LocationStaticMap_node">;
    };
  } | null | undefined;
};
export type LocationStaticMapSubscription = {
  response: LocationStaticMapSubscription$data;
  variables: LocationStaticMapSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "height"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "width"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "height",
    "variableName": "height"
  },
  {
    "kind": "Variable",
    "name": "width",
    "variableName": "width"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationStaticMapSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "NodeUpdatedPayload",
        "kind": "LinkedField",
        "name": "nodeUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "FragmentSpread",
                "name": "LocationStaticMap_node"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootSubscriptionType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LocationStaticMapSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "NodeUpdatedPayload",
        "kind": "LinkedField",
        "name": "nodeUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "kind": "ScalarField",
                    "name": "staticMapUrl",
                    "storageKey": null
                  }
                ],
                "type": "OrganizationLocation",
                "abstractKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "qpid:NJg6gpXtD1NqMVq-uhtg-rrEkAQpeAOsZdmvyR91JZc",
    "metadata": {},
    "name": "LocationStaticMapSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "f36e1094dda952b5a06cb3261364ab63";

export default node;
