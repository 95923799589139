import {
  Carousel,
  Column,
  ContentArea,
  DocumentTile,
  FancyHeader,
  GridNull,
  MaxWidthContainer,
  Pressable,
  Surface,
  Text,
  WysiwygContent,
  showPDFViewer
} from "@gigsmart/atorasu";
import {
  type FragmentContainerInnerComponentProps,
  createRelayFragmentContainer,
  getConnectionNodes,
  graphql
} from "@gigsmart/relay";
import React from "react";

import { LocationUpdates } from "@gigsmart/feature-flags";
import type { ShiftLocationCard_shift$key } from "./__generated__/ShiftLocationCard_shift.graphql";

export function ShiftLocationCard({
  organizationLocation
}: FragmentContainerInnerComponentProps<ShiftLocationCard_shift$key>) {
  const { locationInstructions, attachments } = organizationLocation ?? {};
  const attachmentNodes = getConnectionNodes(attachments);
  const imageAttachments = attachmentNodes
    .filter((item) => item.mimeType !== "application/pdf")
    .map((item) => item.url);
  const fileAttachments = attachmentNodes.filter(
    (item) => item.mimeType === "application/pdf"
  );

  if (
    (!locationInstructions && !attachmentNodes?.length) ||
    LocationUpdates.isDisabled()
  )
    return <GridNull />;

  return (
    <Surface>
      <FancyHeader
        icon="location-dot"
        title="Location Details"
        color="primary"
      />
      <ContentArea gap="standard">
        {locationInstructions ? (
          <>
            <Text weight="bold">Location Information: </Text>
            <WysiwygContent html={locationInstructions} />
          </>
        ) : (
          <Text>
            <Text weight="bold">Location Information: </Text>None
          </Text>
        )}
        {!!imageAttachments.length && (
          <Carousel sources={imageAttachments} thumbSize={32.5} />
        )}
        {fileAttachments.map(({ url, filename }, index: number) => (
          <MaxWidthContainer key={url} maxWidth={180}>
            <Column>
              <Pressable
                testID="select-message-attachment"
                eventEntityType="Pressable"
                eventTargetName="SelectMessageAttachment"
                onPress={() =>
                  showPDFViewer({
                    source: { uri: url ?? "" },
                    filename: filename ?? "Document.pdf"
                  })
                }
              >
                <Surface variant="flat" color="background">
                  <DocumentTile
                    name={filename ?? `Attachment ${index + 1}`}
                    type="PDF"
                    icon="file-pdf"
                  />
                </Surface>
              </Pressable>
            </Column>
          </MaxWidthContainer>
        ))}
      </ContentArea>
    </Surface>
  );
}

export default createRelayFragmentContainer<ShiftLocationCard_shift$key>(
  graphql`
    fragment ShiftLocationCard_shift on Gig {
      id
      organizationLocation {
        locationInstructions
        attachments(first: 50) {
          edges {
            node {
              id
              url
              filename
              mimeType
            }
          }
        }
      }
    }
  `,
  ShiftLocationCard
);
