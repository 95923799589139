/**
 * @generated SignedSource<<00fbf99b0171bc469669dedf71e5c179>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftLocationCard_shift$data = {
  readonly id: string;
  readonly organizationLocation: {
    readonly attachments: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly filename: string | null | undefined;
          readonly id: string;
          readonly mimeType: string;
          readonly url: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly locationInstructions: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ShiftLocationCard_shift";
};
export type ShiftLocationCard_shift$key = {
  readonly " $data"?: ShiftLocationCard_shift$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftLocationCard_shift">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftLocationCard_shift",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationLocation",
      "kind": "LinkedField",
      "name": "organizationLocation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationInstructions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 50
            }
          ],
          "concreteType": "OrganizationLocationAttachmentsConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationLocationAttachmentsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationLocationAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "filename",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mimeType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "attachments(first:50)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Gig",
  "abstractKey": null
};
})();

(node as any).hash = "01e18b1e819225233a0d4c8d5b069b3b";

export default node;
